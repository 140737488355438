<template>
  <div>
    <div>
      <el-form class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" @click="dialogAdd = true">添加</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">发布</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="container">
      <el-table
        :data="data.data"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          prop="content"
          label="内容"
          style="cursor: pointer"
        ></el-table-column>

        <el-table-column
          label="事件时间"
          align="center"
          style="cursor: pointer"
        >
          <template #default="scope"
            >{{ scope.row.eventDate }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="操作"
          width="100"
          style="cursor: pointer"
        >
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click.stop="handleDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :page-size="10"
          :total="data.totalCount"
          @current-change="changePage"
        ></el-pagination>
      </div>
    </div>
    <div>
      <el-dialog v-model="dialogVisible" width="400px">
        <h3 style="text-align: center">确定删除该内容，删除后不能恢复</h3>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="hadeDelete">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <el-dialog v-model="dialogAdd" width="580px" center destroy-on-close>
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="事件时间" prop="eventDate">
            <el-date-picker
              v-model="ruleForm.eventDate"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleForm.content"
              maxlength="300"
              show-word-limit
              rows="12"
              resize="none"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="addSubmit('ruleForm')"
          >确认添加</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      dialogVisible: false,
      dialogAdd: false,
      labelPosition: "right",
      ruleForm: {
        eventDate: "",
        content: "",
      },
      rules: {
        eventDate: [
          {
            required: true,
            message: "请选择事件时间",
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请填写内容",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  methods: {
    async getData() {
      const params = {
        currentPage: 1,
        pageSize: 10,
      };

      const res = await this.$api.newsManagement.companyHistory(params);

      if (res.code === 0) {
        this.data = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    async changePage(val) {
      const params = {
        currentPage: val,
        pageSize: 10,
      };

      const res = await this.$api.newsManagement.companyHistory(params);

      if (res.code === 0) {
        this.data = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },

    // 发布
    async onSubmit() {
      const res = await this.$api.newsManagement.releaseCompany();
      if (res.data.code == 0) {
        this.$confirm("是否继续发布所有内容?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message.success(res.data.msg);
          })
          .catch(() => {
            this.$message({
              message: "已取消发布",
            });
          });
      } else {
        this.$message.error(res.data.msg);
      }
    },

    // 新增一条内容
    addSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var dayjs = require("dayjs");
          let time = dayjs(this.ruleForm.eventDate).format("YYYY-MM");

          const params = {
            eventDate: time,
            content: this.ruleForm.content,
          };
          this.$api.newsManagement.addCompany(params).then((res) => {
            if (res.code == 0) {
              this.$message.success("添加成功");
              this.dialogAdd = false;
              this.ruleForm.eventDate = "";
              this.ruleForm.content = "";
              this.getData();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 删除弹窗
    handleDel(data) {
      this.dialogVisible = true;
      this.itemDel = data;
    },

    // 删除
    async hadeDelete() {
      const res = await this.$api.newsManagement.deleteCompany(
        this.itemDel.infoId
      );
      if (res.data.code == 0) {
        this.getData();
        this.$message.success(res.data.msg);
        this.dialogVisible = false;
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
  mounted() {
    this.getData();
  },
  filters: {},
};
</script>

<style lang="scss" scoped>
.upload-file {
  height: 40px;
  line-height: 40px;
}
.file-img {
  .el-image {
    width: 200px;
    max-height: 200px;
    vertical-align: top;
    &:first-child {
      margin-right: 10px;
    }
  }
}
.container {
  cursor: pointer;
}
.dialog-footer {
  margin-top: 20px;
  text-align: center;
}
.demo-form-inline {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .el-form-item {
    display: flex;
    margin-right: 20px;
    cursor: pointer;
  }
}
</style>
